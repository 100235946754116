import { browser } from '$app/environment';

export function isSmallScreen(): boolean {
  if (browser) {
    return (
      document.documentElement.clientWidth < 720 || document.documentElement.clientHeight < 680
    );
  } else {
    return false;
  }
}

export function routesEqual(a: string, b: string) {
  return (a.endsWith('/') ? a.slice(0, -1) : a) === (b.endsWith('/') ? b.slice(0, -1) : b);
}

export const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;

export const PagesWithAddFunction = [
  '/portal/matches',
  '/portal/players',
  '/portal/matchVenues',
  '/portal/organizations'
];
